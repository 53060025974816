
import Vue from "vue";
import BaseBtn from "@/components/base/Button.vue";
import {mapActions, mapState} from "vuex";

export default Vue.extend({
  name: 'HeaderActions',
  components: {BaseBtn},
  computed: {
    ...mapState('auth', {
      accessToken: 'accessToken',
    }),
  },
  methods: {
    ...mapActions('auth', {
      attempt: 'attempt'
    }),
    click() {
      this.$emit('click')
    },
  }
})
