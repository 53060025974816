import objectKeyToSnakeCase from "@/utils/object-key-to-snake-case";
import {TaxonModule} from "@/store/models/Taxon";

class Seller {
    constructor(
        public uuid: string,
        public name: string,
        public nameEn: string,
        public active: boolean,
        public createdAt: string,
        public updatedAt: string,
        public deletedAt: string | null
    ) {}

    static fromJSON(json: any) {
        return new Seller(
            json['uuid'],
            json['name'],
            json['name_en'],
            json['active'],
            json['created_at'],
            json['updated_at'],
            json['deleted_at'],
        )
    }
}

export class OrderModel {
    constructor(
        public uuid: string,
        public orderUuid: string,
        public productUuid: string,
        public customerUuid: string,
        public sellerUuid: string,
        public description: string,
        public quantity: number,
        public quantityUsed: number,
        public price: number,
        public subtotal: number,
        public createdAt: string,
        public updatedAt: string,
        public expiredAt: string | null,
        public deletedAt: string | null,
        public qrCode: string,
        public seller: Seller | null,
    ) {}

    static fromJSON(json: any) {
        return new OrderModel(
            json['uuid'],
            json['order_uuid'],
            json['product_uuid'],
            json['customer_uuid'],
            json['seller_uuid'],
            json['description'],
            json['quantity'],
            json['quantity_used'],
            json['price'],
            json['subtotal'],
            json['created_at'],
            json['updated_at'],
            json['expired_at'],
            json['deleted_at'],
            json['qr_code'],
            json['seller'] ? Seller.fromJSON(json['seller']) : null,
        )
    }

    static serializeOrder(data = {}) {
        return objectKeyToSnakeCase(data);
    }
}