import {ActionTree, GetterTree, MutationTree} from "vuex";

const findError = (bag: ErrorMessage[], id: string) => bag.find(e => e.id === id)

interface ErrorMessage {
    id: string,
    messages: string[],
}

type ErrorGetter = GetterTree<ErrorState, any>;

export interface ErrorState {
    errors: ErrorMessage[],
}

const state: ErrorState = {
    errors: []
};

const getters: ErrorGetter = {
    hasErrors: (state) => (id: string) => {
        const found = findError(state.errors, id);
        return found;
    },
    getErrors: (state) => (id: string) => {
        const found = findError(state.errors, id);
        if (found) return found.messages || [];
        return [];
    },
};

const actions: ActionTree<ErrorState, any> = {
    push({ commit } ,errors: ErrorMessage[]) {
        commit('push', errors);
    },
    unset({ state, commit }, id: string) {
        const found  = findError(state.errors, id);
        if (found) {
            const index = state.errors.findIndex((e: any) => e.id === id);
            if (index >= 0) commit('unset', index);
        }
    },
    clear({ commit }) {
        commit('clear');
    }
};
const mutations: MutationTree<ErrorState> = {
    clear(state) {
        state.errors = [];
    },
    push(state, errors) {
        errors.forEach((messages: ErrorMessage) => state.errors.splice(0, 0, messages))
    },
    unset(state, index) {
        state.errors.splice(index, 1)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
