export class TaxonModule {
    constructor(
        public uuid: string,
        public taxonomyUuid: string | null,
        public parentUuid: string | null,
        public priority: number,
        public name: string | null,
        public slug: string,
        public lft: number,
        public rgt: number,
        public createdAt: string,
        public updatedAt: string | null,
        public deletedAt: string | null
    ) {}

    static fromJSON(json: any) {
        return new TaxonModule(
            json['uuid'],
            json['taxonomy_uuid'],
            json['parent_uuid'],
            json['priority'],
            json['name'],
            json['slug'],
            json['lft'],
            json['rgt'],
            json['created_at'],
            json['updated_at'],
            json['deleted_at'],
        )
    }
}