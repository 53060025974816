import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import IconClose from "@/components/icons/IconClose.vue";
import IconArrow from "@/components/icons/IconArrow.vue";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FFF',
                secondary: '#000',
                accent: '#E10600',
                anchor: '#000',
            },
        },
    },
    icons: {
        values: {
            close: {
                component:  IconClose
            },
            arrow: {
                component: IconArrow
            }
        }
    }
});
