import client from "@/api/client";
import type {AxiosResponse} from "axios";
import type {IPagination} from "@/store/common.types";
import {IQueryParams} from "@/store/common.types";


const api = {
    product: {
        index: (query: IQueryParams) => client.get(`/product`, {params: query}),
        show: (uuid: string, query: IQueryParams) => client.get(`/product/${uuid}`, {params: query}),
    },
    filter: {
        index: (query: IQueryParams) => client.get(`/product/filter`, {params: query}),
    },
    order: {
        index: (query: IQueryParams) => client.get(`/order`, {params: query}),
        show: (uuid: string) => client.get(`/order/${uuid}`, {}),
        place: (productUuid: string) => client.post(`/order`, {product_uuid: productUuid}),
    },
    taxonomy: {
        index: (query: any) => client.get(`/taxon/${query?.uuid}`, {}),
    }
}

export default api;
