// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {getField, updateField} from "vuex-map-fields";
import {get, has} from "lodash";
import _ from "lodash";
import objectKeyToSnakeCase from "@/utils/object-key-to-snake-case.ts";
import {IApi} from "@/store/common.types";
import {ActionTree, GetterTree, MutationTree} from "vuex";

const paginationMapper = ({
      total = 0,
      per_page = 0,
      current_page = 1,
      last_page = 1,
      from = 0,
      to = 0,
  }) => ({
    page: current_page,
    itemsPerPage: per_page,
    pageStart: from,
    pageStop: to,
    pageCount: last_page,
    itemsLength: total
})


export interface makeCrudParams<T> {
    endpoint: IApi,
    state?: any,
    actions?: ActionTree<any, any>,
    getters?: GetterTree<any, any>,
    mutations?: MutationTree<any>,
    normalizePagination?: any,
    serialize?(x: any): any,
    normalize?(x: any): any,
    serializeInstance?(x: any): any,
    onFetch?(x: any): void,
    onShow?(x: T): any,
    onEdit?(x: T): any,
    onCreate?(x: T): any,
    onCancel?(x: any): any,
    onDelete?(x: any): any,
    onError?(x: any): any,

    onCreated?(r: any): any,
    onUpdated?(r: any): any,

    instance?: any,
    form?: any
}


const serializeQuery = (query: any) => objectKeyToSnakeCase(query);

export function makeCrudModule<T>(name: string, {
    endpoint,
    state = {},
    actions = {},
    getters = {},
    mutations = {},
    normalizePagination = paginationMapper,
    normalize = x => x,
    serializeInstance = x => x,
    onFetch = x => x,
    onShow = x => x,
    onEdit = x => x,
    onCreate = x => x,
    onCancel = x => x,
    onDelete = x => x,
    onError = x => x,

    onCreated = r => r,
    onUpdated = r => r,

    instance = null,
    form = null
}: makeCrudParams<T>) {

    const resetState = () => ({
        query: {
            page: null,
            limit: 15
        },
        items: [],
        loading: false,
        pagination: {
            page: 1,
            itemsPerPage: 15,
            pageStart: 1,
            pageStop: 1,
            pageCount: 1,
            itemsLength: 0
        },
        meta: {},
        ...state
    });

    const crudActions: ActionTree<any, any> = {
        fetch: async ({state, commit}, custom = {}) => {
            // It is not strictly necessary to pass a service,
            // but if none was passed, no data can be loaded.
            if (endpoint === undefined) throw new Error("No API service specified!");
            const query = serializeQuery({
                ...state.query,
                ...custom
            });

            if (state.loading === true) return;

            onFetch(query);

            commit('applyLoading', true);

            try {
                const response = await endpoint.index(query);

                const pagination = response.data;

                commit("applyFetched", normalizePagination(pagination));
                pagination.data.forEach((item: any) => {
                    commit("applyAddItem", normalize(item));
                });

                if (has(response.data, 'meta')) {
                    commit("applyMeta", get(response.data, 'meta', null));
                } else {
                    commit("applyMeta", null);
                }
            } catch (error) {
                onError(error);
                throw error;
            } finally {
                // ...
                commit('applyLoading', false);
            }
        },

        create: async ({dispatch}, ...props) => {
            if (endpoint === undefined) throw new Error("No API service specified!");
            // Proxy to instance module
            return await dispatch('instance/create', ...props);
        },

        show: async ({dispatch}, ...props) => {
            if (endpoint === undefined) throw new Error("No API service specified!");
            // Proxy to instance module
            return await dispatch('instance/show', ...props);
        },

        edit: async ({dispatch}, ...props) => {
            if (endpoint === undefined) throw new Error("No API service specified!");
            // Proxy to instance module
            return await dispatch('instance/edit', ...props);
        },

        destroy: async ({dispatch}, ...props) => {
            if (endpoint === undefined) throw new Error("No API service specified!");
            // Proxy to instance module
            return await dispatch('instance/destroy', ...props);
        },

        cancel: async ({dispatch}, ...props) => {
            // Proxy to instance module
            return await dispatch('instance/cancel', ...props);
        },

        clear: async ({commit, dispatch}) => {
            dispatch('instance/clear');
            commit('applyCleared');
        },

        changePage: async ({state, commit, dispatch}, page) => {
            commit('applyQuery', { ...state.query, page});

            await dispatch('fetch');
        },

        setQuery: ({state, commit}, query) => {
            commit('applyQuery', {...state.query, ...query})
        }
    }

    const crudMutations: MutationTree<any> = {
        applyLoading: (state, flag = true) => {
            state.loading = flag
        },
        applyFetched: (state, pagination) => {
            state.pagination = pagination;
            state.items.splice(0, state.items.length);
        },
        applyAddItem: (state, item) => {
            state.items.splice(state.items.length, 0, item)
        },
        applyMeta: (state, meta = null) => {
            console.log('set meta', meta)
            state.meta = meta;
        },
        applyCleared: (state) => {
            const data = resetState();
            for (const prop in data) state[prop] = data[prop];
        },
        applyQuery: (state, query) => {
            state.query = query;
        },
    }

    return {
        namespaced: true,

        state: () => ({...resetState()}),

        actions: {
            ...crudActions,
            ...actions
        },

        getters: {
            getField,
            ...getters
        },

        mutations: {
            updateField,
            ...crudMutations,
            ...mutations
        },

        modules: instance ? {
            instance: makeCrudInstanceModule(name, {
                endpoint,
                normalize,
                serialize: serializeInstance,
                onCreate,
                onEdit,
                onShow,
                onCancel,
                onDelete,
                onError,
                onCreated,
                onUpdated,
                form,
                ...instance
            })
        } : undefined,

        normalizePagination,
        normalize
    };
}


export function makeCrudInstanceModule<T>(name: string, {
    endpoint,
    normalize = x => x,
    serialize = x => x,
    onCancel = x => x,
    onCreate = x => x,
    onEdit = x => x,
    onShow = x => x,
    onDelete = x => x,
    onError = x => x,
    onCreated = r => r,
    onUpdated = r => r,
    state = {},
    actions = {},
    mutations = {},
    getters = {},
    form = null
}:makeCrudParams<T>) {

    const resetState = () => ({
        query: {},
        instance: {},
        loading: null,
        meta: null,
        form: form ? _.cloneDeep(form) : null,
        ...state
    });

    const crudActions: ActionTree<any, any> = {
        create: async ({state, commit}, props) => {
            const instance = state.form || normalize({});
            commit('applyInstanceCreated', instance);
            onCreate(instance);
        },

        show: async ({state, commit}, data) => {
            if (typeof endpoint.show != 'function') throw new Error("The show endpoint not specified");
            onShow(data?.uuid);
            commit('applyLoading', data?.uuid);

            const query = serializeQuery({
                ...state.query,
                ...data.query
            });

            try {
                console.info('CRUD: show: uuid', JSON.stringify(state.query))
                const response = await endpoint.show(data?.uuid, query);
                commit("applyInstanceLoaded", normalize(response.data));
            } catch (error) {
                onError(error);
                throw error;
            } finally {
                commit('applyLoading', null);
            }

        },

        // eslint-disable-next-line no-unused-vars
        edit: async ({state, commit, dispatch}, uuid) => {
            if (typeof endpoint.edit != 'function' && typeof endpoint.show != 'function' || typeof endpoint.show != 'function') throw new Error("The edit endpoint not specified");
            onEdit(uuid);
            commit('applyLoading', uuid);
            try {
                console.info(`CRUD: edit: ${uuid}`, JSON.stringify(state.query))
                const response =  endpoint.edit ? await endpoint.edit(uuid, state.query) : await endpoint.show(uuid, state.query)  ;
                const normalized = normalize(response.data);

                if (state.form) {
                    const updatedForm: {[key: string]: any} = {};
                    for (const key in form) {
                        updatedForm[key] = normalized[key];
                    }
                    commit("applyInstanceLoaded", normalized);
                    commit("applySetForm", updatedForm);
                } else {
                    commit("applyInstanceLoaded", normalized);
                }
            } catch (error) {
                onError(error);
                throw error;
            } finally {
                commit('applyLoading', null);
            }
        },

        save: async ({state, dispatch}, uuid) => {
            const identity = uuid || state.instance.uuid;
            console.info(`CRUD: store/update: ${identity}`)
            console.info(identity);
            if (identity) {
                return dispatch("update");
            } else {
                return dispatch("store");
            }
        },

        store: async ({state, commit}) => {
            // Prevent saving multiple times.
            if (state.loading) return;
            if (typeof endpoint.store != 'function') throw new Error("The store endpoint not specified");

            commit('applyLoading', true);

            try {
                const instance = serialize(state.form || state.instance);
                const response = await endpoint.store(instance);
                commit("applyInstanceSaved", normalize(response.data));
                onCreated(normalize(response.data));
            } catch (error) {
                onError(error);
                throw error;
            } finally {
                commit('applyLoading', null);
            }
        },

        update: async ({state, commit}) => {
            // Prevent saving multiple times.
            if (state.loading) return;
            if (typeof endpoint.update != 'function') throw new Error("The store endpoint not specified");

            commit('applyLoading', state.instance.uuid);
            try {
                const instance = serialize(state.form || state.instance);
                const response = await endpoint.update(state.instance.uuid, instance);
                const response_data = response.data;
                const normalized = response_data ? normalize(response_data) : null;
                commit("applyInstanceSaved", normalized);
                onUpdated(normalized);
            } catch (error) {
                onError(error);
                throw error;
            } finally {
                commit('applyLoading', null);
            }
        },

        destroy: async ({state, commit, dispatch}, payload) => {
            // Prevent saving multiple times.
            if (state.loading) return;
            if (typeof endpoint.destroy != 'function') throw new Error("The store endpoint not specified");

            const uuid = payload.uuid;
            const identity = uuid || state.instance.uuid || null;
            if (!identity) return;

            onDelete(identity);

            const data = payload.data || {};
            commit('applyLoading', uuid);
            try {
                await endpoint.destroy(identity, data);
                commit("applyInstanceDeleted", identity);
                dispatch(`${name}/fetch`, null, { root:true });
            } catch (error) {
                onError(error);
                throw error;
            } finally {
                commit('applyLoading', null);
            }

        },

        cancel: async ({state, commit}) => {
            // if (state.instance) {
            onCancel(state.instance);
            commit('applyCanceled', null);
            // }
        },

        clear: async ({commit}) => {
            return commit('applyCleared');
        },
    }

    const crudMutations: MutationTree<any> = {
        applyLoading: (state, uuid) => {
            state.loading = uuid
        },
        applyInstanceCreated: (state, instance) => {
            state.instance = instance
        },
        applyInstanceLoaded: (state, instance) => {
            state.instance = instance
        },
        applyInstanceSaved: (state, instance) => {
            state.instance = instance
        },
        applyInstanceDeleted: (state) => {
            state.instance = null;
        },
        applyCanceled: (state) => {
            state.instance = {};
        },
        applySetForm: (state, form) => {
            state.form = {...state.form, ...form}
        },
        applyCleared: (state) => {
            const data = resetState();
            for (const prop in data) state[prop] = data[prop];
            for (const prop in state.form) state.form[prop] = form[prop];
        },
    }

    return {
        namespaced: true,

        state: () => ({...resetState()}),

        actions: {
            ...crudActions,
            ...actions
        },

        getters: {
            getField,
            ...getters
        },

        mutations: {
            updateField,
            ...crudMutations,
            ...mutations
        }
    }
}


