export class ImageModel {
    constructor(
        public sm: string,
        public lg: string,
    ) {}

    static fromJSON(json: any) {
        return new ImageModel(
            json['sm'],
            json['lg'],
        )
    }
}