
import Vue from "vue";

export default Vue.extend({
  props: {
    maxWidth: {
      type: [Number, String],
      default: 'auto'
    }
  },
  name: 'LogoContainer',
  data() {
    return {
      logoSVG: require('@/assets/images/logo_black.svg'),
    }
  },
})
