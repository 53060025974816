
import Vue from "vue";
import LogoContainer from "@/components/common/LogoContiner.vue";
import HeaderActions from "@/components/header/HeaderActions.vue";
import MenuDialog from "@/components/menu/MenuDialog.vue";

export default Vue.extend({
  name: "BaseHeader",
  components: {MenuDialog, HeaderActions, LogoContainer},
  data() {
    return {
      logoSVG: require('@/assets/images/logo_black.svg'),
      dialog: false,
    }
  },
  methods: {
    setDialog(state: boolean) {
      this.dialog = state ? state : !this.dialog;
    },
  }
})
