
import Vue from "vue";
import MenuSectionContainer from "@/components/menu/MenuSectionContainer.vue";
import MenuSectionLink from "@/components/menu/MenuSectionLink.vue";

export default Vue.extend({
  name: 'MenuSection',
  components: {MenuSectionLink, MenuSectionContainer},
  props: ['link'],
})
