import {manager} from '../../../services/auth'
import store from "../../index";
import {ActionTree, GetterTree, MutationTree} from "vuex";

export const authManager = manager;

type AuthGetter = GetterTree<AuthState, any>;

export interface AuthState {
    sub: string | null,
    accessToken: string | null
}

const state: AuthState = {
    sub: null,
    accessToken: null
};

const getters: AuthGetter = {
    authenticated: (state) => state.sub !== null,
};

const actions: ActionTree<AuthState, any> = {
    async attempt() {
        const currentUrl = window.location.pathname;

        return authManager.signinRedirect({ state: window.btoa(currentUrl)});
    },

    async handleRedirectCallback() {
        return authManager.signinRedirectCallback();
    },

    login({ commit }, payload) {
        commit('login', payload);
    },

    async logout() {
        authManager.signoutRedirect();
    },

    async load({ commit }) {
        const user = await authManager.getUser();
        if (user) {
            commit('login', user);
        }
    }
};

const mutations: MutationTree<AuthState> = {

    login(state, payload) {
        state.sub = payload.sub || payload.profile.sub;
        state.accessToken = payload.accessToken || payload.access_token;
    },

    logout(state) {
        //@todo
    },
};


manager.events.addUserLoaded((user) => {
    store.dispatch('auth/login', {
        sub: user.profile.sub,
        accessToken: user.access_token
    });
});

manager.events.addUserUnloaded(() => {
    // console.log('--- user unloaded ---');
})

manager.events.addUserSignedIn(() => {
    // console.log('--- user signed in ---');
});

manager.events.addUserSignedOut(() => {
    // console.log('--- user signed out ---');
})

manager.events.addAccessTokenExpired(() => {
    authManager.removeUser();
    // console.log('--- access token expired ---');
})

manager.events.addSilentRenewError(function (error) {
    store.dispatch('auth/attempt');
});


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
