import {makeCrudModule} from "@/store/crud";
import api from "@/api";
import {TaxonModule} from "@/store/models/Taxon";

export default makeCrudModule<TaxonModule>('taxonomy', {
    endpoint: api.taxonomy,
    normalize: product => TaxonModule.fromJSON(product),
    instance: {
        state: {
            instance: {}
        }
    }
})