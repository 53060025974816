import {AttributeDataType, AttributePresentation} from "@/store/models/Attribute";

export class FilterOption {
    constructor(
        public uuid: string,
        public parentUuid: string,
        public title: string | null,
        public value: string | null,
        public priority: number,
    ) {}

    static fromJSON(json: any) {
        return new FilterOption(
            json['uuid'],
            json['parent_uuid'],
            json['title'],
            json['value'],
            json['priority'],
        )
    }
}

export class FilterModel {
    constructor(
        public id: string,
        public name: string | null,
        public initial: string,
        public type: AttributeDataType,
        public presentation: AttributePresentation,
        public options: FilterOption[],
        public priority: number,
        public suffix: string,
    ) {}

    static fromJSON(json: any) {
        return new FilterModel(
            json['id'],
            json['name'],
            json['default'], // initial
            json['type'],
            json['presentation'],
            json['options'] ? json['options'].map((option: any) => FilterOption.fromJSON(option)) : [],
            json['priority'],
            json['suffix'],
        )
    }
}