
import Vue from "vue";
import LogoContainer from "@/components/common/LogoContiner.vue";
import BaseMenu from "@/components/menu/Menu.vue";

export default Vue.extend({
  name: 'MenuDialog',
  components: {BaseMenu, LogoContainer},
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    }
  }
})
