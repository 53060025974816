import {UserManager, WebStorageStateStore} from "oidc-client-ts";
import type {UserManagerSettings} from "oidc-client-ts";

const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

const authority = process.env.VUE_APP_OIDC_AUTHORITY;
const clientId = process.env.VUE_APP_OIDC_CLIENT_ID;

const settings: UserManagerSettings = {
    authority: `${authority}`,
    client_id: `${clientId}`,
    redirect_uri: `${baseUrl}/auth/callback`,
    response_type: "code",

    response_mode: "query",
    scope: "openid offline_access",

    loadUserInfo: false,
    metadataUrl: `${authority}/oauth/config`,

    post_logout_redirect_uri: `${baseUrl}/`,

    // Do we want to renew the access token automatically when it's about to expire?
    automaticSilentRenew: false,
    silent_redirect_uri: `${baseUrl}/auth/callback`,

    // Do we want to filter OIDC protocol-specific claims from the response?
    filterProtocolClaims: true,

    // Number of seconds before the token expires to trigger the `tokenExpiring` event
    accessTokenExpiringNotificationTimeInSeconds: 60,

    userStore: new WebStorageStateStore({
        store: window.localStorage
    }),
};

export const manager = new UserManager(settings);

