
import Vue from "vue";

export default Vue.extend({
  name: 'LinkText',
  props: ['href', 'isUnderline', 'white', 'className'],
  computed: {
    classes() {
      return ['root', this.isUnderline ? 'underline' : 'animUnderline', this.white && 'white', this.className ];
    }
  },
})
