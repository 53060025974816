<template>
  <component
    :is="type"
    v-bind="$attrs"
    @click="$emit('click')"
    :class="classes"
    :to="to"
    :disabled="loading"
  >
    <slot/>
  </component>
</template>

<script>

import Vue from "vue";

export default Vue.extend({
  name: "BaseBtn",
  props: {
    variant: {
      validator: (prop) => ['outlined', 'contained'].includes(prop),
      default: 'outlined'
    },
    color: {
      validator: (prop) => ['primary', 'secondary', 'accent'].includes(prop),
      default: 'primary'
    },
    hoverType: {
      validator: (prop) => ['fill', 'square'].includes(prop),
      default: 'fill'
    },
    to: String,
    onClick: {
      type: Function,
      default: (x) => x,
    },
    link: Boolean,
    loading: Boolean,
    block: Boolean
  },
  computed: {
    classes() {
      return [
        'root',
        'variant-'+this.variant,
        'color-'+this.color,
        'hover-'+this.hoverType,
        this.loading ? 'disabled' : '',
        this.block ? 'block' : ''
      ];
    },
    type() {
      if (this.link) {
        return 'a'
      } else if (this.to) {
        return 'router-link'
      } else {
        return 'button'
      }
    }
  },
})
</script>

<style scoped lang="scss">
@import "../../style/config/variables";

.root {
  text-transform: lowercase;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8125rem 2rem;
  font-weight: 700;
  text-decoration: none;
  @media (max-width: 1280px){
    padding: 0.46875rem 2rem;
  }

  &.block {
    width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    @media (min-width: 1024px) {
      box-shadow: none;
    }
  }

  &.disabled {
    opacity: .65;
    pointer-events: none;
  }

  &.variant-contained, &.variant-outlined {
    &.hover-square {
      transition: padding 1s;
      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: 1.5rem;
        background-color: $accent;
        transition: width .5s, height .5s, left .5s, margin .5s
      }

      &:hover {
        @media (min-width: 1024px) {
          padding-left: 3rem;
          padding-right: 1rem;

          box-shadow: none;

          &:before {
            content: '';
            width: 1rem;
            height: 1rem;
            left: 1rem;
          }
        }
      }
    }
  }

  &.variant-outlined {
    background-color: unset;
    transition: color .5s, background-color .5s, outline-color .5s;

    &.color-primary {
      outline: 3px solid $primary;
      outline-offset: -3px;
      color: $primary;

      &.hover-fill:hover {
        color: $secondary;
        background-color: $primary;
      }
    }

    &.color-secondary {
      outline: 3px solid $secondary;
      outline-offset: -3px;
      color: $secondary;

      &.hover-fill:hover {
        color: $primary;
        background-color: $secondary;
      }
    }

    &.color-accent {
      outline: 3px solid $yellow;
      outline-offset: -3px;
      color: $secondary;

      &.hover-fill:hover {
        color: $primary;
        background-color: $secondary;
        outline: 3px solid $secondary;
      }
    }
  }

  &.variant-contained {
    transition: color .5s, background-color .5s;
    &:hover {
      outline-offset: -3px;
    }

    &.color-primary {
      background-color: $primary;
      color: $secondary;
      &.hover-fill:hover {
        color: $primary;
        outline: 3px solid $primary;
        background-color: transparent;
      }
    }

    &.color-secondary {
      background-color: $secondary;
      color: $primary;
      &.hover-fill:hover {
        color: $secondary;
        outline: 3px solid $secondary;
        background-color: transparent;
      }
    }

    &.color-accent {
      background-color: $yellow;
      color: $secondary;

      &.hover-fill:hover {
        color: $primary;
        background-color: $secondary;
      }
    }
  }
}


</style>
