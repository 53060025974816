import {TaxonModule} from "@/store/models/Taxon";
import {AttributeModel, AttributePresentation} from "@/store/models/Attribute";
import objectKeyToSnakeCase from "@/utils/object-key-to-snake-case";
import {ImageModel} from "@/store/models/Image";

enum StaticAttributeKey {
    city = 'misto',
    description = 'opis',
    discount = 'znizhka'
}

type ProductLink = {
    title: string,
    value: string
}

class Location {
    constructor(
        public uuid: string,
        public productUuid: string,
        public placeId: string,
        public lat: string,
        public lon: string,
        public quadkey: string,
        public deckey: number,
        public houseNumber: string,
        public house: string | null,
        public road: string,
        public neighbourhood: any,
        public city: string,
        public postcode: string,
        public state: string,
        public country: string,
        public countryCode: string | null,
        public address: string,
    ) {}

    static fromJSON(json: any) {
        return new Location(
            json['uuid'],
            json['product_uuid'],
            json['place_id'],
            json['lat'],
            json['lon'],
            json['quadkey'],
            json['deckey'],
            json['house_number'],
            json['house'],
            json['road'],
            json['neighbourhood'],
            json['city'],
            json['postcode'],
            json['state'],
            json['country'],
            json['country_code'],
            json['address'],
        )
    }
}

class Seller {
    constructor(
        public uuid: string,
        public name: string | null,
        public nameEn: string | null,
        public active: boolean,
        public createdAt: string,
        public updatedAt: string,
        public deletedAt: string | null
    ) {}

    static fromJSON(json: any) {
        return new Seller(
            json['uuid'],
            json['name'],
            json['name_en'],
            json['active'],
            json['created_at'],
            json['updated_at'],
            json['deleted_at'],
        )
    }
}

export class ProductModel {
    constructor(
        public uuid: string,
        public parentUuid: string,
        public productType: string,
        public name: string | null,
        public slug: string,
        public sku: string | null,
        public state: string,
        public image: ImageModel | null,
        public groupUuid: string | null,
        public sellerUuid: string,
        public createdBy: string | null,
        public createdAt: string,
        public updatedAt: string,
        public deletedAt: string | null,
        public values: AttributeModel[],
        public taxons: TaxonModule[],
        public category: TaxonModule | null,
        public seller: Seller | null,
        public images: ImageModel[],
        public locations: Location[],
        public description: string,
        public discount: string,
    ) {}

    get city(): string[] {
        const attributes = this.values.filter(item => item.slug === StaticAttributeKey.city);
        return attributes.map(item => item.text) as string[];
    }

    get links(): ProductLink[] {
        const attributes = this.values.filter(item => item.presentation === AttributePresentation.link);
        return attributes.map(item => ({
            title: item.name || '-',
            value: item.value as string
        }));
    }

    static fromJSON(json: any) {
        return new ProductModel(
            json['uuid'],
            json['parent_uuid'],
            json['product_type'],
            json['name'],
            json['slug'],
            json['sku'],
            json['state'],
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
            json['group_uuid'],
            json['seller_uuid'],
            json['created_by'],
            json['created_at'],
            json['updated_at'],
            json['deleted_at'],
            json['values'] ? json['values'].map((attribute: any) => AttributeModel.fromJSON(attribute)) : [],
            json['taxons'] ? json['taxons'].map((taxon: any) => TaxonModule.fromJSON(taxon)) : [],
            json['category'] ? TaxonModule.fromJSON(json['category']) : null,
            json['seller'] ? Seller.fromJSON(json['seller']) : null,
            json['images'] ? json['images'].map((image: any) => ImageModel.fromJSON(image)) : [],
            json['locations'] ? json['locations'].map((image: any) => Location.fromJSON(image)) : [],
            json['description'],
            json['discount'],
        )
    }

    static serializeProduct(data = {}) {
        return objectKeyToSnakeCase(data);
    }
}

