import {makeCrudModule} from "@/store/crud";
import api from "@/api";
import {OrderModel} from "@/store/models/Order";

export default makeCrudModule<OrderModel>('order', {
    endpoint: api.order,
    normalize: order => OrderModel.fromJSON(order),
    serializeInstance: order => OrderModel.serializeOrder(order),
    instance: {
        state: {
            instance: {}
        }
    },
    actions: {
        placeOrder: async ({state, commit}, uuid) => {
            if (state.loading === true) return;

            commit('applyLoading', true);

            try {
                const response = await api.order.place(uuid);
                commit('instance/applyInstanceCreated', OrderModel.fromJSON(response.data));
            } catch (error) {
                console.log(error)
                throw error;
            } finally {
                commit('applyLoading', false);
            }
        }
    },

})