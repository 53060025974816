import {RouteConfig} from "vue-router";

export const routes: RouteConfig[] = [
    {
        path: '/',
        component: () => import('../views/catalogue/Index.vue'),
    },
    {
        path: '/auth/callback',
        component: () => import('../views/auth/Redirect.vue'),
    },
    {
        path: '/catalogue/:slug?',
        component: () => import('../views/catalogue/Index.vue'),
    },
    {
        path: '/product/:uuid',

        component: () => import('../views/product/View.vue'),
    },
    {
        path: '/order/:uuid',
        meta: {
            auth: true
        },
        component: () => import('../views/order/View.vue'),
    },
    {
        path: '/order',
        meta: {
            auth: true
        },
        component: () => import('../views/order/Index.vue'),
    },
];
