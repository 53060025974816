import api from "@/api";
import {FilterModel} from "@/store/models/Filter";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {getField, updateField} from 'vuex-map-fields';
import {ActionTree, MutationTree} from "vuex";
import _ from "lodash";

const normalizeFilter = (json: any) => FilterModel.fromJSON(json);

interface FilterState {
    fields: FilterModel[],
    values: any,
    loading: boolean,
}

const state: FilterState = {
    fields: [],
    values: {},
    loading: false
}

const getters = {
    getField,
}

const mutations: MutationTree<FilterState> = {
    updateField,
    applyLoading(state, flag){
        state.loading = flag;
    },
    applyClearFields(state) {
        state.fields = [];
    },
    applyAddField(state, field) {
        state.fields.push(field);
    },
    applySortFields(state) {
        const fields = _.cloneDeep(state.fields);
        fields.sort((fieldA, fieldB) =>  fieldA.priority - fieldB.priority);

        state.fields = fields
    },
    applyClearValue(state) {
        state.values = {}
    },
    applySetValue(state, instance) {
        const values = _.cloneDeep(state.values);
        values[instance.key] = instance.value;

        state.values = values;
    },
}
const actions: ActionTree<FilterState, any> = {
    async fetch({ state, commit}, data){
        if (state.loading === true) return;

        commit('applyLoading', true);

        try {
            const response = await api.filter.index(data?.query);
            const fields = response.data;
            commit("applyClearValue");
            commit("applyClearFields");

            fields.forEach((field: any) => {
                const normalizedField = normalizeFilter(field);
                commit("applyAddField", normalizedField);
                if(data?.values) {
                    commit("applySetValue", { key: normalizedField.id, value: data.values[normalizedField.id] });
                }
            });

            commit("applySortFields");
        } catch (error) {
            console.info(error)
            throw error;
        } finally {
            commit('applyLoading', false);
        }
    },

    setValue({commit}, instance) {
        commit("applySetValue", instance);
    },

    clearFields({commit}) {
        commit("applyClearFields");
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
