export enum AttributeDataType {
    text = 'text',
    number = 'number',
    integer = 'integer',
    boolean = 'boolean'
}

export enum AttributePresentation {
    text = 'text',
    number = 'number',
    select = 'select',
    selectMultiple = 'select-multiple',
    checkbox = 'checkbox',
    radio = 'radio',
    tree = 'tree',
    textarea = 'textarea',
    link = 'link'
}

export class AttributeModel {
    constructor(
        public id: string,
        public attributeId: string,
        public productUuid: string,
        public valueUuid: string,
        public value: string | null | string[],
        public groupId: string,
        public name: string | null,
        public slug: string,
        public type: AttributeDataType,
        public required: boolean,
        public filterable: boolean,
        public userDefined: boolean,
        public translatable: boolean,
        public multiple: boolean,
        public presentation: AttributePresentation,
        public isGlobal: boolean,
        public priority: number,
        public text: string | null,
    ) {}

    static fromJSON(json: any) {
        return new AttributeModel(
            json['id'],
            json['attribute_id'],
            json['product_uuid'],
            json['value_uuid'],
            json['value'],
            json['group_id'],
            json['name'],
            json['slug'],
            json['type'],
            json['required'],
            json['filterable'],
            json['user_defined'],
            json['translatable'],
            json['multiple'],
            json['presentation'],
            json['is_global'],
            json['priority'],
            json['text'],
        )
    }
}
