
import Vue from "vue";
import MenuSection from "@/components/menu/MenuSection.vue";

export default Vue.extend({
  name: 'BaseMenu',
  components: {MenuSection},
  data() {
    return {
      menu: {
        links: [
          {
            label: 'Всі пропозиції',
            section: null,
            type: "menu.link",
            link: {link: '/'}
          },
          {
            label: 'Особистий кабінет',
            section: null,
            type: "menu.link",
            link: {link: '#'}
          }
        ]
      }
    }
  },
  computed: {
    classes() {
      return ['nav'];
    }
  },
})
