import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import error from './modules/error';
import product from './modules/product';
import filter from './modules/filter';
import order from './modules/order';
import taxonomy from './modules/taxonomy';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    error,
    product,
    filter,
    order,
    taxonomy,
  }
})
