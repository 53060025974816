
import Vue from "vue";
import LinkText from "@/components/base/LinkText.vue";

export default Vue.extend({
  name: 'MenuSectionLink',
  components: {LinkText},
  props: ['link', 'label', 'single'],
  computed: {
    classes() {
      return ['title', this.link && 'link', this.single ? 'singleLink' : 'dropdownLink'];
    }
  },
})
