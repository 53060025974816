"use strict";

import type {AxiosRequestConfig, ParamsSerializerOptions} from "axios";
import axios from "axios";
import qs from 'qs';
import store from "../store";
import {manager} from "@/services/auth";


const clearSession = () => {
    store.dispatch('auth/attempt')
      .then(() => {
          console.log('cleared')
      })
      .catch(() => {
          window.location.href = '/';
      });
}


const config: AxiosRequestConfig = {
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false, // Check cross-site Access-Control
    paramsSerializer: {
      serialize: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets'})
      }
    },
    headers: {'Content-Type': 'application/json'},
    responseType: 'json',
};

const client = axios.create(config);

// 1. Request interceptors
client.interceptors.request.use(async (config) => {
    const authenticated = store.getters["auth/authenticated"];
    if (authenticated) {
        const accessToken = store.state.auth.accessToken;
        config.headers = {...config.headers, 'Authorization': `Bearer ${accessToken}`};
    }
    return config;
});

client.interceptors.request.use(async (config) => {
    config.headers = {...config.headers, 'Accept-Language': `uk-UA`};
    return config;
});

// 2. Response interceptors
// 2.1 Handle response errors
client.interceptors.response.use(
    response => response,
    async error => {
        // store.dispatch('error/clear');
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                const auth = manager;
                try {
                    // Get a new access token...
                    auth.startSilentRenew();
                    const access_token = await auth.getUser().then(user => user ? user.access_token : null);
                    try {
                        // And retry te request with a new access token.
                        const config = error.config;
                        config.headers['Authorization'] = `Bearer ${access_token}`;
                        try {
                            const response = await axios.request(config);
                            return Promise.resolve(response);
                        } catch (error) {
                            clearSession();
                            return Promise.reject(error);
                        }
                    } catch (error) {
                        // Logout user if the token is invalid.
                        clearSession();
                        return Promise.reject(error)
                    }
                } catch (error) {
                    // Logout user if token refresh didn't work.
                    clearSession();
                    return Promise.reject(error);
                }
            }
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            if (error.response.status === 422) {
                // store.dispatch('error/push', get(error.response.data, 'errors', {error: 'Validation error'}));
            } else if (error.response.status === 400) {
                // store.dispatch('error/push', get(error.response.data, 'errors', {error: 'Invalid request'}));
            } else if (error.response.status === 500) {
                // store.dispatch('error/push', get(error.response.data, 'errors', {error: 'Server error'}));
            } else if (error.response.status === 503) {
                // store.dispatch('error/push', get(error.response.data, 'errors', {error: 'The service is temporary unavailable'}));
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            // store.dispatch('error/push',  [{error: 'The request was made but no response was received'}]);
        } else {
            // Something happened in setting up the request that triggered an Error
            // store.dispatch('error/push', [{error: 'The server is temporarily unavailable or something happened in setting up the request. '}]);
        }

        return Promise.reject(error);
    }
);

export default client;
