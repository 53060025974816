
import Vue from 'vue';
import BaseHeader from "@/components/header/Header.vue";

export default Vue.extend({
  name: 'App',
  components: {BaseHeader},
  data: () => ({
    //
  }),
});
