import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import {routes} from "@/router/routes";
import store from '@/store';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})


// Handle authentication checks.
router.beforeEach(async (to, from, next) => {
  const authenticated = store.getters['auth/authenticated'];
  const requiresAuth = to.matched.some((route) => route.meta.auth);

  if (requiresAuth) {
    // this route requires auth, check if logged in if not, redirect to login page.
    if (authenticated) {
      next();
    } else {
      await store.dispatch('auth/attempt');
      next(false);
    }
  } else {
    next();
  }
});

// Handle page metadata.
router.beforeEach((to, from, next) => {
  console.log(to.matched.slice());
  const nearest = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearest) {
    document.title = `${nearest.meta.title} — ITCT Loyalty`;
  } else {
    document.title = `ITCT Loyalty`;
  }
  next();
});

export default router
